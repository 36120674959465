<template>
  <div class="home">
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Dashboard
    </h2>
    <div class="card shadow-sm bg-white border p-8 mt-2">
      <div class="flex flex-col lg:flex-row items-center justify-between">
        <h5
          class="my-6 text-1xl font-semibold text-gray-700 dark:text-gray-200"
        >
          Logs dos últimos 7 dias.
        </h5>
        <div class="flex items-center justify-end gap-3">
           <router-link :to="`/pedidos`">
            <Button color="primary" :text="`Pesquisar Pedidos`" class="btn" />
          </router-link> 
          <router-link :to="`/vendas/ultimas`">
            <Button color="primary" :text="`Últimas Vendas`" class="btn" />
          </router-link>
        </div>
      </div>
      <div class="card">
        <Datatable
          :headers="headersLogs"
          :data="Logs"
          :options="options"
          color="primary"
        />
      </div>
    </div>

    <div class="card shadow-sm bg-white border p-8 mt-2">
      <div class="flex items-center justify-between">
        <h5
          class="my-6 text-1xl font-semibold text-gray-700 dark:text-gray-200"
        >
          Erros dos últimos 7 dias.
        </h5>
      </div>
      <div class="card">
        <Datatable
          :headers="headersErrors"
          :data="Errors"
          :options="errOpt"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, ref } from "vue";
import { GET } from "@/services/api";
import Datatable from "@/components/Elements/Datatable.vue";
import Button from "@/components/Elements/Button.vue";

export default defineComponent({
  name: "LogsScreen",
  components: {
    Datatable,
    Button,
  },
  setup() {
    const loader = inject("loading");
    const Logs = ref([]);
    const Errors = ref([]);
    const modal = inject("modalLogs");
    const modalErr = inject("modalLogError")
    const headersLogs = [
      { key: "Data", value: "data" },
      { key: "Usuário", value: "usuario" },
      { key: "Rota", value: "rota" },
      { key: "Aplicação", value: "programa" },
      { key: "Ações", value: "" },
    ];
    const headersErrors = [
      { key: "Data", value: "data" },
      { key: "Usuário", value: "usuario" },
      { key: "Rota", value: "rota" },
      { key: "Aplicação", value: "programa" },
      { key: "Mensagem", value: "message" },
      { key: "Ações", value: "" },
    ];

    const showLogs = async (param) => {
      const res = await GET(`/logs/${param.id}`);
      modal.open(res[0].body, res[0].metodo);
    }; 

    const showErrors = async (param) =>{
     try {
      const res = await GET(`/logs/errors/${param.id}`);
      modalErr.open(res[0].body, res[0].metodo, res[0].message)
     } catch (error) {
      console.log(error)
     }
    }

    const getLogs = async () => {
      loader.open();
      try {
        const logsData = await GET(`/logs/lastWeek`);
        Logs.value = logsData;
        const tz = new Date().getTimezoneOffset();
        logsData.map((l) => {
          const data = new Date(l["data"]);
          const dia = data.getDate();
          const mes = data.getMonth();
          const ano = data.getFullYear();
          const hora = data.getHours();
          const minutos = data.getMinutes();
          const dataFormatada = new Date(ano, mes, dia, hora, minutos);
          l["data"] =
            dataFormatada.toLocaleDateString(tz) +
            " - " +
            dataFormatada.toLocaleTimeString(tz).slice(0, 5);
        });

        const errorData = await GET("/logs/errors/lastWeek");
        Errors.value = errorData;
        errorData.map((e) => {
          e["data"] = new Date(e["data"]).toLocaleDateString(tz) + " - " + new Date(e["data"]).toLocaleTimeString(tz).slice(0, 5);
        });

        loader.close();
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      getLogs();
    });

    const options = [
      {
        icon: "eye",
        title: "Ver mais",
        action: (param) => showLogs(param),
      },
    ];

    const errOpt = [
      { icon: "eye", title: "Ver mais", action: (param) => showErrors(param) },
    ];

    return { headersLogs, Logs, options, headersErrors, Errors, errOpt };
  },
});
</script>

<style scoped></style>
